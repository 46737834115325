import request from "@/api/index.js";

//更新报名
function submitComplain(data) {
  return request({
    method: "post",
    url: "api/system/submitComplain",
    data,
  });
}
//上传文件
function uploadOss(data) {
  // if (!file) {
  //   console.log("file文件为空");
  //   return;
  // }
  // const formData = new FormData();
  // formData.append("file", file);
  return request({
    method: "post",
    url: "api/blade-resource/oss/uploadOss?type=report",
    data,
    // data: formData,
  });
}
// /api/blade-resource/oss/uploadOss?type=report
// function detailaction(data) {
//     return request({
//         method: 'get',
//         url: '/brand/detailaction',
//         params:data
//     })
// }

export { submitComplain, uploadOss };
