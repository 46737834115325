<template>
  <div class="home">
    <div class="banner">
      <!-- <img src="@/img/banner2.png" alt="" /> -->
      <div class="title">
        监察反舞弊<span style="color: #1677ff">举报信息</span>
      </div>
    </div>
    <!-- <div :class="isPC ? 'contentMainPC' : 'contentMain'">
      <div class="lineblue"></div>
      <div class="content">
        <img class="flower1" src="@/img/flower.png" alt="" />
        <img class="flower2" src="@//img/flower.png" alt="" />
      </div> -->
    <!-- 判断展示pc或移动form -->
    <pcForm v-if="isPC" />
    <mobileForm v-else />
    <!-- </div> -->
  </div>
</template>

<script>
// import { submitComplain } from "@/api/report/index.js";
// import Identify from "./components/identify.vue";
// import baseURL from "@/api/baseUrl.js";
import pcForm from "./components/pcform/pcform.vue";
import mobileForm from "./components/mobileform/mobileform.vue";
export default {
  components: { pcForm, mobileForm },
  data() {
    return {
      screenWidth: 0,
      isPC: this.isPCNot(),
    };
  },
  computed: {},
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 750) {
          this.isPC = false;
        } else {
          this.isPC = true;
        }
        console.log("this.isPC", this.isPC);
      },
    },
  },
  created() {
    // console.log("baseUrl", this.baseURL);
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    isPCNot() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      console.log("userAgentInfo", userAgentInfo);
      return flag;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
