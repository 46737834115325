<template>
  <div class="mobile">
    <div class="contentMain">
      <div class="lineblue"></div>
      <div class="content">
        <img class="flower1" src="@/img/flower.png" alt="" />
        <img class="flower2" src="@//img/flower.png" alt="" />
      </div>
      <van-form ref="ruleForm" @failed="onFailed" @submit="onSubmit">
        <!-- 通过 pattern 进行正则校验 -->
        <div class="reportInfo">
          <div class="title">
            <div class="icon">
              <img src="@/img/icon.png" alt="" />
            </div>
            <div class="detail">举报对象</div>
          </div>
          <div class="reportObject">
            <van-field
              name="radio"
              label="举报对象"
              :required="true"
              :colon="true"
              label-width="150px"
            >
              <template #input>
                <van-radio-group
                  v-model="ruleForm.complainType"
                  direction="horizontal"
                  @change="presonkindEvent1"
                >
                  <van-radio name="1">员工</van-radio>
                  <van-radio name="2">公司</van-radio>
                  <van-radio name="3">其他</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              label-width="150px"
              :required="true"
              :colon="true"
              label="被举报所属公司"
              v-model="ruleForm.complainTenant"
              name="pattern"
              placeholder="请输入"
              :rules="[
                {
                  // pattern: /^([\u4e00-\u9fa5]{2,20}|[a-zA-Z]{2,20})$/,
                  // pattern: /^[a-zA-Z\u4e00-\u9fa5]+$/,
                  pattern: /^[a-zA-Z\u4e00-\u9fa5]{1,20}$/,
                  message: '仅支持2~20位的汉字、字母',
                },
              ]"
            />
            <!-- 通过 validator 进行函数校验 -->
            <!-- :rules="[{ validator, message: '请输入正确内容' }]" -->
            <van-field
              label-width="150px"
              :required="true"
              :colon="true"
              v-model="ruleForm.complainName"
              label="被举报人姓名"
              name="validator"
              placeholder="请输入"
              :rules="[
                {
                  // /^[a-zA-Z\u4e00-\u9fa5\s\p{P}]+$/
                  // pattern: /^([\u4e00-\u9fa5]{2,20}|[a-zA-Z]{2,20})$/,
                  ///^[\p{L}\p{P}]{1,20}$/
                  pattern:
                    /^[\u4e00-\u9fa5\s\·\~\！\@\#\￥\%\……\&\*\（\）\--\-\+\=\[\]\｛\｝\、\ | \；\'\：\“\”\《\》\？\，\.\，]+$/,
                  message: '仅支持2~20位的汉字、符号',
                },
              ]"
            />

            <!-- 通过 validator 进行异步函数校验 -->
            <van-field
              type="tel"
              :colon="true"
              v-model="ruleForm.complainPhone"
              name="asyncValidator"
              label="被举报人电话"
              placeholder="请输入"
              label-width="150px"
              :rules="[
                // { validator: asyncValidator, message: '请输入正确内容' },
                { required: true, message: '手机号码不能为空！' },
                // /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                {
                  validator: (val) => {
                    return /^[^]{1,12}$/.test(val);
                  },
                  message: '手机号格式不正确',
                },
              ]"
            />
            <van-field
              label-width="150px"
              :required="true"
              :colon="true"
              v-model="ruleForm.complainTopic"
              label="举报主题"
              rows="1"
              autosize
              type="textarea"
              maxlength="50"
              show-word-limit
              name="validator"
              placeholder="请输入"
              :rules="[
                { pattern: /^[\u4e00-\u9fa5]+$/, message: '仅支持汉字' },
              ]"
            />

            <van-field
              label-width="150px"
              v-model="ruleForm.content"
              rows="2"
              autosize
              :required="true"
              :colon="true"
              label="举报内容"
              :rules="[
                // { validator: asyncValidator, message: '请输入正确内容' },
                { required: true, message: '举报内容不能为空！' },
              ]"
              type="textarea"
              maxlength="200"
              placeholder="请输入"
              show-word-limit
            />
            <van-field
              v-model="ruleForm.otherMessage"
              rows="2"
              autosize
              :colon="true"
              label="其他知情"
              type="textarea"
              maxlength="50"
              placeholder="请输入"
              show-word-limit
              label-width="150px"
            />
            <van-field name="uploader" label="文件上传" label-width="150px">
              <template #input>
                <!-- :before-delete="deletevideoEvent" -->
                <!-- :before-read="handlebefread" -->
                <van-uploader
                  type="file"
                  v-model="filelist"
                  :after-read="afterRead"
                  :max-size="20 * 1024 * 1024"
                  @oversize="onOversize"
                  :max-count="1"
                  :colon="true"
                  accept="*"
                >
                  <van-button
                    size="small"
                    type="info"
                    style="
                      font-size: 16px !important;
                      width: 100px;
                      padding: 20px 0;
                    "
                    >上传文件</van-button
                  >
                </van-uploader>
                <div
                  style="
                    margin-left: 10px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    color: #909bb0;
                  "
                >
                  (附件仅支持单文件上传，最大为20M)
                </div>
              </template>
            </van-field>
          </div>
          <!-- 举报信息 -->
          <div class="reportPreson">
            <div class="title">
              <div class="icon">
                <img src="@/img/icon.png" alt="" />
              </div>
              <div class="detail">举报人信息</div>
            </div>
            <!-- <van-field
              name="radio"
              label=""
              :required="true"
              :colon="true"
              label-width="150px"
            > -->
            <!-- <template #input> -->
            <div
              class="left"
              style="margin-left: 15px; padding: 0px 10px; margin-top: 15px"
            >
              <van-checkbox v-model="isReal" shape="square" @change="realEvent"
                >实名举报</van-checkbox
              >
              <!-- </template>
            </van-field> -->
              <div class="propt">
                如需财务部、法务部与您取得进一步联系，请填写正确的联系方式。(您的身份信息将会<span
                  style="color: #1677ff"
                  >严格保密</span
                >)
              </div>
            </div>
            <div v-show="isReal">
              <van-field
                name="radio"
                label="举报人身份"
                :required="true"
                :colon="true"
                label-width="150px"
              >
                <template #input>
                  <van-radio-group
                    v-model="ruleForm.createComplainType"
                    direction="horizontal"
                    @change="presonkindEvent2"
                  >
                    <van-radio name="1">员工</van-radio>
                    <van-radio name="2">公司</van-radio>
                    <van-radio name="3">其他</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                type="tel"
                :colon="true"
                v-model.trim="ruleForm.createEmail"
                name="validator"
                label="邮箱"
                placeholder="请输入"
                label-width="150px"
                :rules="[
                  { validator: validatorEmail, message: '请输入正确邮箱' },
                ]"
              />
              <!-- :rules="[
                  { required: false },
                  {
                    pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: '请输入正确邮箱',

                  },
                ]" -->
              <van-field
                type="tel"
                :colon="true"
                v-model.trim="ruleForm.createMobile"
                name="validator"
                label="举报人电话"
                placeholder="请输入"
                label-width="150px"
                :rules="[
                  { validator: validatorCode, message: '手机号格式不正确' },
                ]"
              />
              <!-- :rules="[
                  { required: false },
                  {
                    validator: (val) => {
                      return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
                        val
                      );
                    },
                    message: '手机号格式不正确',
                  },
                ]"
              验证码 -->
              <!-- :rules="[{ validatorCode, message: '验证码不正确,请重新输入' }]" -->
              <van-field
                label="验证码"
                :colon="true"
                name="validator"
                :required="true"
                v-model.trim="ruleForm.code"
              >
                <!-- :rules="[{ validatorCode, message: '验证码不正确,请重新输入' }]" -->
                <template template #input>
                  <el-input
                    v-model.trim="ruleForm.code"
                    placeholder="请输入验证码"
                    size="small"
                    style="width: 60%"
                    @blur="codeRule"
                  />
                  <span
                    class="login-code"
                    style="
                      position: absolute;
                      right: 0;
                      top: 2px;
                      left: 65%;
                      width: 10%;
                    "
                  >
                    <Identify :identifyCode="identifyCode"></Identify>
                  </span>
                  <span
                    class="el-icon-refresh-right"
                    style="
                      position: absolute;
                      left: 95%;
                      top: 13px;
                      cursor: pointer;
                    "
                    @click="refreshCode"
                  />
                </template>
              </van-field>
            </div>
          </div>
          <div style="margin: 16px" class="submitclass">
            <van-button
              style="
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
              "
              square
              block
              type="info"
              native-type="submit"
              >立即提交</van-button
            >
            <!-- v-no-more-click -->
          </div>
        </div>
      </van-form>
    </div>
    <van-overlay :show="successshow" @click="successshow = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <img class="motipimg" src="@/img/mosuccess.png" />
          <div class="subTip">提交成功</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="errorshow" @click="errorshow = false">
      <div class="wrapper">
        <div class="block2" @click.stop>
          <img class="motipimg" src="@/img/error.png" />
          <div class="subTip">提交失败</div>
          <div class="rewrite" @click="resetEvent()">重新填写</div>
        </div>
      </div>
    </van-overlay>
    <!-- <van-dialog
      v-model="successshow"
      :showConfirmButton="false"
      title=""
      width="30%"
    >
      <img class="motipimg" src="@/img/mosuccess.png" />
      <div>提交成功</div>
    </van-dialog> -->
    <!-- <van-dialog v-model="successshow" title="" show-cancel-button>
      <img class="motipimg" src="@/img/error.png" />
    </van-dialog> -->
  </div>
</template>

<script>
import { Dialog } from "vant";
import { submitComplain, uploadOss } from "@/api/report/index.js";
import Identify from "../identify.vue";
import baseURL from "@/api/baseUrl.js";
export default {
  components: {
    Identify,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      successshow: false,
      errorshow: false,
      baseURL: baseURL,
      isReal: false,
      ruleForm: {
        complainType: [], //举报对象:1员工2公司3其他
        complainTenant: "", //被举报主体
        complainName: "", //被举报人姓名
        complainPhone: "", //举报人电话
        complainTopic: "", //举报主题
        content: "", //举报内容
        otherMessage: "", //其他知情
        file: "", //材料
        createComplainType: "", //举报人对象:1员工2公司3其他
        createMobile: "", //举报人联系方式
        createEmail: "", //举报人邮箱
        code: "",
      },
      // pattern: /\d{6}/,
      uploader: [],
      checked: "",
      filelist: [],
      // 验证码
      code: this.$route.query.code,
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",
      identifyCode: "",
      params: {},
      fileSuccess: "", //0文件上传中 1上传成功
    };
  },
  computed: {},
  created() {
    localStorage.clear();
  },
  mounted() {
    // 初始化验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    localStorage.setItem("code", this.code);
  },
  methods: {
    // 校验函数验证码 ，返回 true 表示校验通过，false 表示不通过
    validatorCode(val) {
      if (!val) {
        return true;
      } else {
        console.log("telVal", val, /^[^]{1,12}$/.test(val));
        // /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
        return /^[^]{1,12}$/.test(val);
      }
      // if (val !== this.identifyCode) {
      //   this.refreshCode();
      //   return false;
      // } else {
      //   return true;
      // }
      // return /1\d{10}/.test(val);
    },
    validatorEmail(val) {
      if (!val) {
        return true;
      } else {
        console.log("eamVal", val);
        // /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        return /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(val);
      }
    },
    codeRule() {
      if (this.ruleForm.code !== this.identifyCode) {
        this.refreshCode();
        // this.$toast.fail("验证码错误，请重新输入");
        this.ruleForm.code = "";
      }
    },
    ceshi() {
      console.log("111");
      this.$refs.ruleForm.validateAll().then(
        (res) => {
          console.log("全部校验成功");
        },
        (res) => {
          console.log("校验失败");
        }
      );
    },
    handlebefread(e) {
      console.log("eee", e);
      // if (e.size >  1048576000) {
      //   showFailToast("选择/录制视频不能超过1000M");
      //   return false;
      // }
      // return true;
    },
    //刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    //生成验证上的随机数，验证码中的数从identifyCodes中取，
    // makeCode(o, l) {
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        // this.identifyCode += this.identifyCodes[this.randomNum(0, o.length)];
      }
    },
    realEvent(val) {
      console.log("val", val);
      this.ruleForm.createComplainType = "";
      this.ruleForm.createMobile = "";
      this.ruleForm.createEmail = "";
      this.refreshCode();
    },
    //生成随机数,这里是生成
    //Math.random()方法返回大于等于0小于1的一个随机数
    //随机数 = Math.floor(Math.random() * 可能的总数 + 第一个可能的值)
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    // validator(val) {
    //   return /1\d{10}/.test(val);
    // },
    // 异步校验函数返回 Promise
    // asyncValidator(val) {
    //   return new Promise((resolve) => {
    //     Toast.loading("验证中...");

    //     setTimeout(() => {
    //       Toast.clear();
    //       resolve(/\d{6}/.test(val));
    //     }, 1000);
    //   });
    // },
    presonkindEvent1(value) {
      console.log("kind", value);
      this.ruleForm.complainType = value;
    },
    presonkindEvent2(value) {
      console.log("kind", value);
      this.ruleForm.createComplainType = value;
    },
    onSubmit(values) {
      //       this.$refs.ruleForm.validateAll().then((res) => {
      //        console.log('全部校验成功')
      //  },(res)=>{
      //        console.log('校验失败')
      //  });
      console.log("submit111111111111111", values);
      this.params = this.ruleForm;
      this.params.isReal = this.isReal ? 1 : 0;
      if (
        this.isReal &&
        this.params.createMobile == "" &&
        this.params.createEmail == ""
      ) {
        // this.$message({
        //   message: "勾选实名后，举报人邮箱电话填其一",
        //   type: "warning",
        // });
        this.$toast.fail("实名后，举报人邮箱电话填其一");
        return;
      }
      if (this.params.complainType == "") {
        this.$toast.fail("请选择举报对象类型");
        return;
      }
      if (this.isReal && this.params.createComplainType == "") {
        this.$toast.fail("请选择举报人对象类型");
        return;
      }
      if (this.isReal && this.ruleForm.code !== this.identifyCode) {
        this.refreshCode();
        this.$toast.fail("验证码错误，请重新输入");
        this.ruleForm.code = "";
        return;
      }
      if (this.fileSuccess === 0) {
        this.$toast.fail("材料上传中,请成功后提交!");
        return;
      }
      // if (this.params.code) {
      delete this.params.code;
      // }
      console.log("params", this.params, this.params.isReal);
      this.debounce(this.submitEvent(this.params), 3000);
      // submitComplain(this.params).then((res) => {
      //   console.log("提交成功res", res);
      //   // if (res.code === 200) {
      //   //   this.dialogVisible = true;
      //   // }
      // });
    },
    submitEvent(params) {
      console.log("params", params, this.params);
      submitComplain(this.params).then((res) => {
        console.log("提交成功res", res);
        if (res.code === 200) {
          this.successshow = true;
          this.filelist = [];
          this.resetEvent();
          setTimeout(() => {
            this.successshow = false;
            localStorage.clear();
            this.$router.push("/");
          }, 1000);
        } else {
          this.errorshow = true;
          // this.filelist = [];
          // this.resetEvent();
          setTimeout(() => {
            this.errorshow = false;
          }, 1000);
        }
        this.fileSuccess = "";
      });
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
      this.$toast.fail("请规范填写表单内容");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      this.fileSuccess = 0; //上传中
      // 此时可以自行将文件上传至服务器
      console.log(file);
      var File = file.file;
      console.log("File=", File);
      var data = new FormData();
      data.append("file", File);
      uploadOss(data).then((res) => {
        console.log("shangchuang", res);
        if (res.code === 200) {
          this.ruleForm.file = res.data;
          this.$toast.success("材料上传成功");
          file.status = "";
          file.message = "";
          this.fileSuccess = 1; //上传成功
        } else {
          this.$toast.fail("材料上传失败，请重新上传");
          file.status = "failed";
          file.message = "上传失败";
          this.filelist = [];
        }
      });
      // setTimeout(() => {
      //   file.status = "failed";
      //   file.message = "上传失败";
      // }, 50000);
    },
    // 超过大小的文件会被自动过滤
    onOversize(file) {
      console.log(file);
      // Toast("文件大小不能超过 20M");
      this.$toast.fail("文件大小不能超过 20M");
    },
    //防抖
    debounce(callback, wait) {
      var timer = null;
      return function () {
        if (timer) {
          // console.log("等待时间内重复触发，一直重新计时");
          clearTimeout(timer);
        }

        timer = setTimeout(function () {
          // console.log("停了1s后，可以执行了");
          callback();
        }, wait);
      };
    },
    //biaodan
    resetEvent() {
      // showFailToast("请规范填写信息");
      // errorInfo.values = {};
      this.errorshow = false;
      for (var key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
      this.isReal = "";
      this.filelist = [];
    },
    // deletevideoEvent(file) {
    //   file.content = "";
    //   // this.videoList = [];
    //   // console.log('this.videoList ', this.videoList);
    //   // this.videourl = '';
    //   // this.zuopin = '';
    //   // console.log('删除视频', file, this.videourl, this.zuopin);
    //   // console.log('1111', this.videourl, this.zuopin);
    // },
  },
};
</script>

<style scoped lang="scss">
@import "./scss/mobileform.scss";
</style>
