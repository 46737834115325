<template>
  <div>
    <div class="contentMainPC">
      <div class="lineblue"></div>
      <div class="content">
        <img class="flower1" src="@/img/flower.png" alt="" />
        <img class="flower2" src="@//img/flower.png" alt="" />
      </div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="175px"
          class="demo-ruleForm"
          label-position="left"
          size="medium"
        >
          <!-- 举报信息板块 -->
          <div class="reportInfo">
            <div class="title">
              <div class="icon">
                <img src="@/img/icon.png" alt="" />
              </div>
              <div class="detail" style="font-size: 24px">被举报信息</div>
            </div>
            <el-form-item label="举报对象:" prop="complainType">
              <el-radio-group
                @input="presonkindEvent1"
                v-model="ruleForm.complainType"
              >
                <el-radio :label="1">员工</el-radio>
                <el-radio :label="2">公司</el-radio>
                <el-radio :label="3">其它</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="被举报所属公司" prop="complainTenant">
              <el-input
                placeholder="请输入被举报所属公司"
                v-model="ruleForm.complainTenant"
              ></el-input>
            </el-form-item>
            <el-form-item label="被举报人姓名" prop="complainName">
              <el-input
                placeholder="请输入被举报人姓名"
                v-model="ruleForm.complainName"
              ></el-input>
            </el-form-item>
            <el-form-item label="被举报人电话" prop="complainPhone">
              <el-input
                placeholder="请输入被举报人电话"
                v-model="ruleForm.complainPhone"
              ></el-input>
            </el-form-item>
            <!-- onkeyup="this.value=this.value.replace(/[^\u4e00-\u9fa5]/g,'')" -->
            <el-form-item label="举报主题" prop="complainTopic">
              <el-input
                placeholder="请输入举报主题"
                v-model="ruleForm.complainTopic"
              ></el-input>
            </el-form-item>
            <el-form-item label="举报内容" prop="content">
              <el-input
                placeholder="请输入举报内容"
                :show-word-limit="true"
                type="textarea"
                v-model="ruleForm.content"
                maxlength="200"
                :rows="3"
                :clearable="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="其它" prop="otherMessage">
              <el-input
                placeholder="请输入"
                type="textarea"
                :show-word-limit="true"
                maxlength="50"
                :rows="2"
                v-model="ruleForm.otherMessage"
              ></el-input>
            </el-form-item>
            <el-form-item label="证据上传" prop="file">
              <el-upload
                class="upload-demo"
                :action="
                  baseURL + 'api/blade-resource/oss/uploadOss?type=report'
                "
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                :on-success="fileOkEvent"
                :on-change="handleChange"
              >
                <el-button size="" type="primary" style="font-size: 16px"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip" style="font-size: 17px">
                  只能上传一个文件，且不超过20M
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="center">
            <img src="~@/assets/img/center.png" alt="" />
          </div>
          <div class="personInfo" style="margin-top: 100px">
            <div class="title">
              <div class="icon">
                <img src="@/img/icon.png" alt="" />
              </div>
              <div class="detail" style="font-size: 24px">举报人信息</div>
            </div>
            <el-checkbox class="real" @change="realEvent" v-model="isReal"
              >实名举报</el-checkbox
            >
            <div class="propt">
              如需财务部、法务部与您取得进一步联系，请填写正确的联系方式。
              (您的身份信息将会<span style="color: #1677ff">严格保密</span>)
            </div>
            <!-- <div class="propt"> -->

            <!-- </div> -->
            <div v-if="isReal">
              <el-form-item label="举报人身份" prop="createComplainType">
                <el-radio-group
                  @input="presonkindEvent2"
                  v-model="ruleForm.createComplainType"
                >
                  <el-radio :label="1">员工</el-radio>
                  <el-radio :label="2">公司</el-radio>
                  <el-radio :label="3">其它</el-radio>
                </el-radio-group>
                <!-- <el-checkbox-group v-model="ruleForm.createComplainType">
                      <span>（可多选）</span>
                      <el-checkbox label="员工" name="type"></el-checkbox>
                      <el-checkbox label="公司" name="type"></el-checkbox>
                      <el-checkbox label="其他" name="type"></el-checkbox>
                    </el-checkbox-group> -->
              </el-form-item>
              <el-form-item label="邮箱" prop="createEmail">
                <el-input
                  placeholder="请输入邮箱号"
                  v-model.trim="ruleForm.createEmail"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="createMobile">
                <el-input
                  placeholder="请输入"
                  v-model.trim="ruleForm.createMobile"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <!-- <el-input v-model="ruleForm.name"></el-input> -->
                <el-input
                  v-model.trim="ruleForm.code"
                  placeholder="请输入正确的验证码"
                  size="small"
                  style="width: 60%"
                />
                <span
                  class="login-code"
                  style="
                    position: absolute;
                    right: 0;
                    top: 2px;
                    left: 65%;
                    width: 10%;
                  "
                >
                  <Identify :identifyCode="identifyCode"></Identify>
                </span>
                <span
                  class="el-icon-refresh-right"
                  style="
                    position: absolute;
                    left: 95%;
                    top: 13px;
                    cursor: pointer;
                  "
                  @click="refreshCode"
                />
              </el-form-item>
            </div>
          </div>
          <el-form-item class="goReport">
            <el-button
              class="reportBtn"
              v-no-more-click
              type="primary"
              @click="submitForm('ruleForm')"
              >立即提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" width="26%">
      <div><img src="~@/assets/img/success.png" alt="" /></div>
      <div
        style="
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #343434;
          line-height: 28px;
        "
      >
        提交成功
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogVisibleError" width="26%">
      <div><img src="~@/assets/img/error.png" alt="" /></div>
      <div
        style="
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #343434;
          line-height: 28px;
        "
      >
        提交失败
      </div>
      <div style="margin-top: 20px">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="resetEdit()">重新填写</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { submitComplain } from "@/api/report/index.js";
import Identify from "../identify.vue";
import baseURL from "@/api/baseUrl.js";
export default {
  components: { Identify },
  data() {
    var codeRule = (rule, value, callback) => {
      if (value !== this.identifyCode) {
        this.refreshCode();
        callback(new Error("验证码错误,请重新填写"));
      } else {
        callback();
      }
    };
    return {
      fileSize: "",
      activekind: "primary",
      dialogVisible: false,
      dialogVisibleError: false,
      baseURL: baseURL,
      isReal: false,
      ruleForm: {
        complainType: [], //举报对象:1员工2公司3其他
        complainTenant: "", //被举报主体
        complainName: "", //被举报人姓名
        complainPhone: "", //举报人电话
        complainTopic: "", //举报主题
        content: "", //举报内容
        otherMessage: "", //其他知情
        file: "", //材料
        createComplainType: "", //举报人对象:1员工2公司3其他
        createMobile: "", //举报人联系方式
        createEmail: "", //举报人邮箱
        code: "",
      },
      // isReal: "", //是否实名
      rules: {
        complainType: [
          { required: true, message: "请选择举报对象", trigger: "change" },
        ],
        complainTenant: [
          { required: true, message: "请输入被举报所属公司", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "联系人长度在 2 到 20 个字符",
            trigger: "blur",
          },
          {
            pattern: /^([\u4e00-\u9fa5]{2,20}|[a-zA-Z]{2,20})$/,
            message: "仅支持2~20位的汉字、字母",
          },
        ],
        complainName: [
          { required: true, message: "请输入被举报人姓名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
          {
            pattern:
              /^[\u4e00-\u9fa5\s\·\~\！\@\#\￥\%\……\&\*\（\）\--\-\+\=\[\]\｛\｝\、\ | \；\'\：\“\”\《\》\？\，\.\，]+$/,
            message: "仅支持2~20位的汉字、字符",
          },
        ],
        complainPhone: [
          {
            // pattern: /^1[3456789]\d{9}$/,
            pattern: /^[^]{1,12}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        createMobile: [
          {
            // pattern: /^1[3456789]\d{9}$/,
            pattern: /^[^]{1,12}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        complainTopic: [
          { required: true, message: "请输入举报主题", trigger: "change" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符,只支持文字",
            trigger: "blur",
          },
          {
            pattern: /^[\一-\龥]{1,50}$/,
            message: "仅支持文字",
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入举报内容", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 1 到 200 个字符",
            trigger: "change",
          },
          // {
          //   pattern: /^[a-zA-Z0-9\s\p{P}]*$/u,
          //   message: "只支持文字符号和字母",
          //   trigger: "blur",
          // },
        ],
        otherMessage: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        createComplainType: [
          { required: true, message: "请选择举报人身份", trigger: "change" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "change" },
          {
            required: true,
            validator: codeRule,
            trigger: "blue",
          },
        ],
        file: [
          // {
          //   validator: fileSizeRule,
          //   trigger: "change",
          // },
        ],
        createEmail: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      fileList: [],
      code: this.$route.query.code,
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",
      identifyCode: "",
      params: {},
      codeRule: codeRule,
      // fileSizeRule: fileSizeRule,
    };
  },
  computed: {},
  created() {
    console.log("baseUrl", this.baseURL);
    this.$store.commit("updateConfirm", false);
    localStorage.clear();
  },

  mounted() {
    // 初始化验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    localStorage.setItem("code", this.code);
  },
  methods: {
    //刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    //生成验证上的随机数，验证码中的数从identifyCodes中取，
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        // this.identifyCode += this.identifyCodes[this.randomNum(0, o.length)];
      }
    },
    //生成随机数,这里是生成
    //Math.random()方法返回大于等于0小于1的一个随机数
    //随机数 = Math.floor(Math.random() * 可能的总数 + 第一个可能的值)
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    realEvent(val) {
      console.log("val", val);
      this.ruleForm.createComplainType = "";
      this.ruleForm.createMobile = "";
      this.ruleForm.createEmail = "";
      this.ruleForm.code = "";
      this.refreshCode();
    },
    // 提交表单
    submitForm() {
      // let params = {};
      this.params = this.ruleForm;
      this.params.isReal = this.isReal ? 1 : 0;

      if (
        this.isReal &&
        this.params.createMobile == "" &&
        this.params.createEmail == ""
      ) {
        this.$message({
          message: "勾选实名后，举报人邮箱电话填其一",
          type: "warning",
        });
        return;
      }
      if (this.isReal && this.ruleForm.code !== this.identifyCode) {
        this.refreshCode();
        this.$message({
          message: "验证码错误，请重新输入",
          type: "warning",
        });
        this.ruleForm.code = "";
        return;
      }
      if (this.params.complainType == "") {
        this.$message({
          message: "请选择举报对象类型",
          type: "warning",
        });
        return;
      }
      if (this.isReal && this.params.createComplainType == "") {
        this.$message({
          message: "请选择举报人对象类型",
          type: "warning",
        });
        return;
      }
      console.log("params", this.params, this.params.isReal);
      this.$refs["ruleForm"].validate((valid) => {
        console.log("22222222");
        if (valid) {
          console.log("1111111111");
          // this.submit();
          //验证码校验成功就可以调接口提交表单了
          // if (this.params.code) {
          delete this.params.code;
          // }
          submitComplain(this.params).then((res) => {
            console.log("提交成功res", res);
            if (res.code === 200) {
              this.dialogVisible = true;
              this.fileList = [];
              this.resetForm("ruleForm");
              setTimeout(() => {
                this.dialogVisible = false;
                localStorage.clear();
                this.$router.push("/");
              }, 1000);
            } else {
              this.dialogVisibleError = true;
              // this.fileList = [];
              // this.resetForm("ruleForm");
              setTimeout(() => {
                this.dialogVisibleError = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    // submit: async function () {
    //   if (
    //     this.ruleForm.code.toLowerCase() !== this.identifyCode.toLowerCase()
    //   ) {
    //     // this.$msg({ type: "error", message: "请填写正确验证码" });
    //     this.refreshCode();
    //     this.loading = false;
    //     return;
    //   } else {
    //     // this.params.code
    //     if (this.params.code) {
    //       delete this.params.code;
    //     }
    //     //验证码校验成功就可以调接口提交表单了
    //     submitComplain(this.params).then((res) => {
    //       console.log("提交成功res", res);
    //     });
    //   }
    // },
    fileOkEvent(response, file, fileList) {
      console.log("response, file, fileList", response, file, fileList);
      if (response.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
      }
      this.ruleForm.file = response.data;
    },
    presonkindEvent1(value) {
      console.log("kind", value);
      this.ruleForm.complainType = value;
    },
    presonkindEvent2(value) {
      console.log("kind", value);
      this.ruleForm.createComplainType = value;
    },
    // submitForm(formName) {
    //   // submitComplain().then((res) => {
    //   //   console.log(res);
    //   // });
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       alert("submit!");
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      // 本次选择了 ${
      //     files.length
      //   } 个文件，共选择了 ${files.length + fileList.length} 个文件
      this.$message.warning(`当前限制上传 1个文件，`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resetEdit() {
      this.dialogVisibleError = false;
      this.resetForm("ruleForm");
    },
    handleChange(file, fileList) {
      //获取上传文件大小
      let fileSize = Number(file.size / 1024 / 1024);
      console.log("file.size", file.size);
      this.fileSize = fileSize;
      if (fileSize > 20) {
        this.$message({
          message: "上传文件大小不能超过20M",
          type: "warning",
        });
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./scss/pcform.scss";
</style>
