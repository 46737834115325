import axios from "axios";
// toast主要用于在请求之前开启loading弹窗
// import { Toast } from 'vant';
import baseURL from "./baseUrl";
// var host;
// process.env是Nodejs提供的一个API，它返回一个包含用户环境信息的对象。
// 通过NODE_ENV 判断当前环境是生产环境(production)还是开发环境(development) 自动切换不同域名
// 开发环境和生产环境
// if (process.env.NODE_ENV == "development") {
//   host = "http://shop.bufantec.com/bufan";
// } else {
//   host = "http://shop.bufantec.com/bufan";
// }

// 创建axios实例
const instance = axios.create({
  // baseURL  公共接口路径
  baseURL: baseURL,
  // timeout 超时时间
  timeout: 50000,
});
// 请求头
// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// var loading;
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    /*
        判断请求类型是get还是post请求  统一请求传参写法
        如get请求传参
        axios.get("http://a.com",{
            params:{
                start:1
            }
        })
        下面设置之后就可以这样写 统一了get请求传参和post请求传参的写法
        axios.get("http://a.com",{
            start:1
        })
    */
    // loading = Toast.loading({
    //   message: '加载中...',
    //   duration: 0, // 持续展示 toast
    //   forbidClick: true, // 禁止背景点击
    // });
    if (config.method.toLowerCase() == "get") {
      config.params = config.data;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器

instance.interceptors.response.use(
  (response) => {
    //请求数据获取到之后关闭loading
    // loading.clear();
    // config.headers['token'] = getToken() //获取请求头 myAuth本地存储的token
    // let token = window.localStorage.getItem("accessToken")
    // if (token) {
    //   //将token放到请求头发送给服务器,将tokenkey放在请求头中
    //   config.headers.accessToken = token;
    //   //也可以这种写法
    //   // config.headers['accessToken'] = Token;
    //   return config;
    // }
    const res = response.data;
    return res;
  },
  (error) => {
    //请求数据失败也关闭loading
    // loading.clear();
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default instance;
